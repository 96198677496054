.welcome {
  padding: 16px 8px 16px 8px;
  color: #333;
}

.title {
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 16px;
  font-style: italic;
  color: #666;
}

.logo {
  text-align: center;
}

.nlogo {
  text-align: center;
  margin-bottom: 16px;
}

.confirm {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 16px;
  text-align: left;
  padding: 0 8px;
  display: flex;
}

.description .icon {
  font-size: 28px;
  display: flex;
  align-items: center;
}

.description .text {
  flex-grow: 1;
  padding-left: 8px;
}

.div {
  margin: 16px 30px;
}
.how-it-works {
  padding: 0 8px;
}

.how-it-works li {
  margin: 16px 0;
}

.how-it-works ol {
  margin: 0 8px;
}

.small-icon {
  font-size: 14px;
}
