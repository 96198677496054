@import '@sassvars';

.container {
  /* text-align: center; */
  padding: 1em;
}

.big-logo {
  width: 400px;

  @include mobile {
    max-width: 90vw;
  }
}
