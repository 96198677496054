@import '@sassvars';

.container {
  font-family: Lato, sans-serif;
  padding: 2em 2.5em;

  background-color: #fafafa;
  border-radius: 3px;
  max-width: 480px;
}

.logo {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.blurb {
  text-align: left;
  margin: 12px 0;
}

.cta {
  margin: auto;
  display: flex;
  justify-content: center;
}

.label {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 4px;
}

.email {
  width: 100%;
  margin: 0.25em 0;
  max-width: 100%;
  outline: 0;
  text-align: left;
  padding: 0.42em 0.75em;
  background: $white;
  border: 1px solid hsl(0, 0%, 80%);
  color: $black;
  border-radius: 0.25rem;
  line-height: 16px;
  font-size: 16px;
  min-height: 38px;
  font-family: 'Lato', sans-serif;

  &:focus {
    border: 1px solid $blue-primary;
  }
}

.email {
  margin-bottom: 1rem;
}

.back {
  text-align: center;
  color: #444;
  cursor: pointer;
}

.back span {
  display: inline-block;
  width: 70%;
  margin-top: 1rem;
}

.error {
  color: $red;
}

.errorContainer {
  height: 1.5rem;
}
