@import '@sassvars';

.ssoBtn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid $black;
  border-radius: 4px;
  background: $white;
  margin: 0.5rem auto;
  cursor: pointer;
  width: 100%;
  max-width: 16em;

  &:hover {
    box-shadow: $box-shadow--std;
  }
}
